import React from 'react'
import { BsTwitter, BsInstagram, BsGithub } from 'react-icons/bs'
import { FaCodepen } from 'react-icons/fa'

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://twitter.com/delvoid" target="_blank" rel="noreferrer">
        <BsTwitter />
      </a>
    </div>
    <div>
      <a href="https://github.com/delvoid" target="_blank" rel="noreferrer">
        <BsGithub />
      </a>
    </div>
    <div>
      <a href="https://codepen.io/delvoid" target="_blank" rel="noreferrer">
        <FaCodepen />
      </a>
    </div>
    <div>
      <a href="https://instagram.com/delvoid" target="_blank" rel="noreferrer">
        <BsInstagram />
      </a>
    </div>
  </div>
)

export default SocialMedia
