import { motion } from 'framer-motion'

import { AppWrap } from '../../wrapper'
import { images } from '../../constants'
import './Header.scss'

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
}

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div className="badge-cmp app__flex">
          {/* <span>👋</span> */}
          <div>
            <p className="head-text">
              Hello, I'm <span>Delvoid</span>
            </p>
            <p className="p-text">I'm a full stack developer</p>

            <a href="#contact" className="btn">
              Let's chat
            </a>
          </div>
        </div>
        {/* 
        <div className="tag-cmp app__flex">
          <p className="p-text">Full stack web developer</p>
          <p className="p-text">Helping you build your dreams</p>
        </div> */}
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.profile} alt="profile_bg" />
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.hex}
        alt="profile_circle"
        className="overlay_circle"
      />
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.javascript, images.node, images.react].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>
    <motion.img
      whileInView={{ scale: [0, 1] }}
      transition={{ duration: 1, ease: 'easeInOut' }}
      src={images.hex}
      alt="profile_circle"
      className="overlay_circle2"
    />
  </div>
)

export default AppWrap(Header, 'home')
