import React, { useState } from 'react'
import { HiOutlineMail } from 'react-icons/hi'
import { FaDiscord } from 'react-icons/fa'
import { AppWrap, MotionWrap } from '../../wrapper'
import { client } from '../../client'
import { Circles } from '../../components'

import './Footer.scss'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  const [formError, setFormError] = useState({})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleValidtaion = () => {
    const emailPattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g
    setFormError({})

    let errors = {}
    let valid = true
    if (!formData.name) {
      valid = false
      errors['name'] = 'Please provide a name'
    }
    if (!formData.email || !emailPattern.test(formData.email)) {
      valid = false
      errors['email'] = 'Please input a valid email'
    }

    if (!formData.message) {
      valid = false
      errors['message'] = 'Please provide a message'
    }
    setFormError(errors)

    return valid
  }

  const handleSubmit = () => {
    setLoading(true)

    if (!handleValidtaion()) {
      setLoading(false)
      return
    }

    const contact = {
      _type: 'contact',
      name: formData.name,
      email: formData.email,
      message: formData.message,
    }

    client
      .create(contact)
      .then(() => {
        setLoading(false)
        setIsFormSubmitted(true)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <div className="app__footer-header">
        <h2 className="head-text">
          Intrested in working togther? <span>Lets Chat.</span>
        </h2>
        <p className="p-text">New projects, freelance inquiry or even a coffee.</p>
      </div>
      <div className="app__footer-container">
        <div className="app__footer-cards">
          <div className="app__footer-card ">
            <HiOutlineMail />
            <a href="mailto:delvoid.dev@gmail.com" className="p-text">
              delvoid.dev@gmail.com
            </a>
          </div>
          <div className="app__footer-card">
            <FaDiscord />
            <div className="p-text">Delvoid#8237</div>
          </div>
        </div>
        {!isFormSubmitted ? (
          <div className="app__footer-form app__flex">
            <div className="app__flex">
              <input
                className={`p-text ${formError.name ? 'field-error' : ''}`}
                type="text"
                placeholder="Your Name"
                name="name"
                value={name}
                onChange={handleChangeInput}
              />
            </div>
            {formError.name && <p className="error">{formError.name}</p>}
            <div className="app__flex">
              <input
                className={`p-text ${formError.email ? 'field-error' : ''}`}
                type="email"
                placeholder="Your Email"
                name="email"
                value={email}
                required
                onChange={handleChangeInput}
              />
            </div>
            {formError.email && <p className="error">{formError.email}</p>}
            <div>
              <textarea
                className={`p-text ${formError.message ? 'field-error' : ''}`}
                placeholder="Your Message"
                value={message}
                name="message"
                onChange={handleChangeInput}
              />
            </div>
            {formError.message && <p className="error">{formError.message}</p>}
            <div className="app_footer-button">
              <button type="button" className="p-text" onClick={handleSubmit}>
                {!loading ? 'Send Message' : 'Sending...'}
              </button>
            </div>
            <Circles classes="circles" />
            <Circles classes="circles2" />
          </div>
        ) : (
          <div>
            <h3 className="head-text">Thank you for getting in touch!</h3>
          </div>
        )}
      </div>
    </>
  )
}

export default AppWrap(MotionWrap(Footer, 'app__footer'), 'contact', 'app__primary')
